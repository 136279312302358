@import '@/services/scss/variables.scss';
@import '@/services/scss/mixin.scss';

$min-block: 1;
$max-block: 10;

.product_details_container {
	position: relative;
	.product_details {
		display: flex;
		flex-wrap: wrap;
		margin-right: -15px;
		margin-left: -15px;
		.product_block {
			padding-right: 15px; /* Adjust as needed */
			padding-left: 15px; /* Adjust as needed */
		}
	}
}

.product_info {
	.head {
		background-color: #111827;
		border-radius: 10px 10px 0px 0px;
		height: 60px;
		padding-inline: 20px;
		padding-block: 8px;
		.title {
			font-size: 14px;
			color: getColor(white);
			line-height: 24px;
			font-weight: 600;
			display: flex;
			justify-content: space-between;
			@include itemsCenter;
			.register {
				color: #111827;
				font-weight: 700;
				font-size: 16px;
			}
		}
	}

	.info {
		padding: 0px 20px 0px 20px;
		position: relative;
		border-radius: 0px 0px 10px 10px;

		.prescription {
			@include absolute(20px, 20px, auto, auto);
			svg {
				color: $greyD;
			}
		}
		h3 {
			font-size: 18px;
			margin: 0;
			max-width: 85%;
			color: getColor(grey900);
			font-weight: 'semi-bold';
			span {
				font-size: 18px;
				color: getColor(grey600);
				font-weight: 300;
				margin-left: 5px;
			}
		}
		.qty_label {
			font-size: 12px;
		}
		.form {
			font-size: 16px;
			padding-top: 10px;
		}
		p {
			font-size: 10px;
			color: $greyD;
			span {
				color: $primary;
			}
		}

		.company_warp {
			display: flex;
			align-items: center;
			gap: 10px;
			flex-shrink: 0;
			border-radius: 5px;
			// justify-content: space-between;

			.company_logo {
				width: 30px;
				height: 30px;
				border-radius: 3px;
				flex-shrink: 0;
				border: 1px solid $grey300;
			}
		}
	}
	.discount {
		color: #fff;
		background-image: url('../../../assets/svg/discount.svg');
		background-size: 100% 100%;
		background-repeat: no-repeat;
		font-weight: 600;
		padding: 5px 20px 5px 5px;
		margin-left: 5px;
		min-width: 65px;
		span {
			margin-left: 10px;
		}
	}
}
.product_info_mobile {
	padding-bottom: 20px;
	.head {
		background-color: #111827;
		border-radius: 10px 10px 0px 0px;
		height: 60px;
		padding-inline: 20px;
		padding-block: 8px;
		.title {
			font-size: 14px;
			color: getColor(white);
			line-height: 24px;
			font-weight: 600;
			display: flex;
			justify-content: space-between;
			@include itemsCenter;
			.register {
				color: #111827;
				font-weight: 700;
				font-size: 16px;
			}
		}
	}

	.info {
		padding: 0px 20px 0px 20px;
		position: relative;
		border-radius: 0px 0px 10px 10px;

		.prescription {
			@include absolute(20px, 20px, auto, auto);
			svg {
				color: $greyD;
			}
		}
		h3 {
			font-size: 18px;
			margin: 0;
			max-width: 85%;
			color: getColor(grey900);
			font-weight: 'semi-bold';
			span {
				font-size: 18px;
				color: getColor(grey600);
				font-weight: 300;
				margin-left: 5px;
			}
		}
		.qty_label {
			font-size: 12px;
		}
		.form {
			font-size: 16px;
			padding-top: 10px;
		}
		p {
			font-size: 10px;
			color: $greyD;
			span {
				color: $primary;
			}
		}

		.company_warp {
			display: flex;
			align-items: center;
			gap: 10px;
			flex-shrink: 0;
			border-radius: 5px;
			justify-content: space-between;

			.company_logo {
				width: 30px;
				height: 30px;
				border-radius: 3px;
				flex-shrink: 0;
				border: 1px solid $grey300;
			}
		}
	}
	.discount {
		color: #fff;
		background-image: url('../../../assets/svg/discount.svg');
		background-size: 100% 100%;
		background-repeat: no-repeat;
		font-weight: 600;
		padding: 5px 20px 5px 5px;
		margin-left: 5px;
		min-width: 65px;
		span {
			margin-left: 10px;
		}
	}
}
.product_info_mobile_container {
	display: none;
}
//1050px
@media (max-width: 1050px) {
	.product_info {
		display: none;
	}
	.product_info_mobile_container {
		display: block;
		margin-bottom: 20px;
	}
}
@media (max-width: 1600px) {
	.product_info_mobile {
		.head {
			padding-inline: 10px;
		}
		.info {
			padding: 0px 10px 0px 10px;
		}
	}
	.product_info_container {
		.price_container {
			display: flex;
			flex-direction: column;
			justify-content: flex-start !important;
			align-items: flex-start !important;
			margin-top: 10px;
		}
	}
}

.offer_slider {
	padding-top: 20px;
	padding-inline: 20px;
	opacity: 1;
	transform: translateX(0);
	transition: transform 0.5s ease-in-out;

	button {
		background-color: $white;
		border: 1px solid $grey300;
		border-radius: 12px;
		padding: 9px 9px;
		margin-right: 10px;
		@include lastChildMargin;
		font-size: 12px;
		cursor: pointer;
	}
	//disabled button
	button:disabled {
		background-color: #ced4da; /* Gray background color for disabled button */
		color: #6c757d; /* Gray text color for disabled button */
		cursor: not-allowed;
		&:hover {
			background-color: #ced4da; /* Gray background color for disabled button */
			color: #6c757d; /* Gray text color for disabled button */
		}
	}
	.next {
		background-color: $primary;
		color: $white;
		border-radius: 100%;
		width: 40px;
		height: 40px;
		z-index: 1;
		//hover
		&:hover {
			background-color: $primary400;
			color: $white;
		}
	}
	.previous {
		background-color: $primary;
		color: $white;
		border-radius: 100%;
		width: 40px;
		height: 40px;
		z-index: 1;
		//hover
		&:hover {
			background-color: $primary400;
			color: $white;
		}
	}
}

.other_verientsnext {
	background-color: getColor(primary_bg);
	color: getColor(primary);
	border-radius: 100%;
	width: 24px;
	height: 24px;
	z-index: 1;
	cursor: pointer;
	display: flex;
	align-items: center;
	//hover
	&:hover {
		background-color: $primary400;
		color: $white;
	}
}

.p_description {
	background-color: #fff !important;
	p,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	span {
		background-color: #fff !important;
	}
}

// product.module.scss
.responsiveTableWrapper {
	overflow-x: auto;
	-webkit-overflow-scrolling: touch; // For smooth scrolling
  }
  
  .responsiveTableWrapper table {
	width: 100%;
	border-collapse: collapse;
	overflow-x: auto;
	min-width: 600px; // Ensuring the table doesn't collapse completely on smaller screens
  }
  
  .responsiveTableWrapper td,
  .responsiveTableWrapper th {
	padding: 8px;
	text-align: left;
	border: 1px solid #ddd;
  }
  
  @media (max-width: 768px) {
	.responsiveTableWrapper table {
	  min-width: 100%; // Adjust table width to 100% of the container on small screens
	}
  }
  

// .product_overview {
// 	height: 700px;
// 	overflow: hidden;
// 	text-overflow: ellipsis;
// 	position: relative;
// 	&:after {
// 		content: "See more";
// 		position: absolute;
// 		bottom: 0;
// 		left: 0;
// 		width: 100%;
// 		height: 300px; /* Adjust shadow height as needed */
// 		text-align: center;
// 		background: linear-gradient(
// 			to top,
// 			rgba(0, 0, 0, 0.5) 0%,
// 			transparent 100%
// 		);
// 	}
// }
