.similarHealthPackage_head {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-top: 30px;
	padding-bottom: 30px;
	padding-right: 15px;
	h5 {
		margin-bottom: 0;
	}
}
.similarHealthPackage_head_slidericon {
	display: flex;
	align-items: center;
	gap: 24px;
}
.similarHealthPackage_cardrow {
	display: flex;
	justify-content: space-between;
	gap: 24px;
	width: 100%;
	overflow: scroll;
	&::-webkit-scrollbar {
		visibility: hidden;
	}
}
.similarHealthPackage_cardrow_eplipses {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 24px;
	margin-top: 60px;
}
