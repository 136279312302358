.testDetailsHead {
	width: 100%;
	background: #ffffff;

	&_top {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 15px 20px;
		display: flex;
		gap: 10px;
		background: var(--ltGreyscale900);
		color: var(--ltWhite);
	}

	&_testCard {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 1rem;
		flex: 0.7;
		padding: 20px;

		.vendor_image {
			width: 36px;
			height: 36px;
			padding: 4px 4px;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 4px;
			border: 1px solid var(--ltGreyscale300);
			background: #fff;
		}
	}

	&_bottom {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 10px;
		padding: 15px 20px;
		border-top: 1px solid var(--Greyscale-300, #eeeff2);
		overflow-x: auto;
		
		.booktest_btn {
			display: flex;
			align-items: center;
			// justify-content: center;
			justify-content: space-between;
			gap: 10px;

			.discountedPrice_2 {
				display: none;
			}
		}
	}
}

.testDetailsHeadCard_includes_test {
	padding-left: 10px;
	display: flex;
	align-items: center;
	gap: 5px;
	& > li {
		list-style: none;
	}
}

// .testCard_patient_btn {
// 	width: 140px;
// 	display: flex;
// 	justify-content: flex-end;
// 	align-items: center;
// }

@media (max-width: 1320px) {
	.testDetailsHead {
		&_top {
			flex-direction: column;
			align-items: flex-start;
		}

		// &_bottom {
		// 	width: 100%;
		// 	flex-direction: column;
		// 	align-items: flex-start;
		// 	justify-content: space-between;
		// 	padding: 15px 20px;
		// 	border-top: 1px solid var(--Greyscale-300, #eeeff2);

		// 	.discountedPrice_1 {
		// 		display: none;
		// 	}
		// 	.regular_price {
		// 		display: none;
		// 	}

		// 	.booktest_btn {
		// 		width: 100%;
		// 		display: flex;
		// 		align-items: center;
		// 		// justify-content: center;
		// 		justify-content: space-between;
		// 		gap: 10px;

		// 		.discountedPrice_2 {
		// 			display: flex;
		// 			align-items: center;
		// 			gap: 10px;
		// 		}
		// 	}
		// }
	}
}

@media (min-width: 768px) and (max-width: 1399px) {
	.testDetailsHeadCard_text_bottom {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: space-between;
		gap: 10px;
	}

	.testDetailsHeadCard_text_bottom_disc {
		width: 80%;
		margin-right: auto;
		display: flex;
		justify-content: space-between;
		gap: 5px;
	}
}
