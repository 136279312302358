.featureHighlightCard {
	display: flex;
	align-items: center;
	gap: 10px;
	justify-content: space-between;

	.image {
		width: 60px;
		min-width: 60px;
		max-width: 60px;
		height: 60px;
		border-radius: 50%;
		background: #f3f9f8;
		padding: 12px;
	}

	.contents {
		display: flex;
    flex-direction: column;
		gap: 6px;
	}
}
