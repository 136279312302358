// styles/notification.module.scss
@import '@/services/scss/variables.scss';
@import '@/services/scss/mixin.scss';
.notification {
	cursor: pointer;
	background-color: #f4feff;
	border: 1px solid getColor(primary100);
	&.read {
		background-color: #fff !important;
		border: 1px solid getColor(grey300);
	}
	.left {
		display: flex;
		flex-direction: column;

		.icon {
			height: 56px;
			width: 56px;
			min-width: 56px;
			min-height: 56px;
			border-radius: 50%;
			background-color: #eee;
			display: flex;
			align-items: center;
			justify-content: center;
			&.danger {
				background-color: #f5eff0;
				svg {
					fill: getColor(error);
				}
			}
			&.success {
				background-color: getColor(primary_bg);
				svg {
					fill: getColor(primary);
				}
			}
			&.info {
				background-color: #d2dcec;
				svg {
					fill: #0062ff;
				}
			}
			&.warning {
				background-color: #ece7d6;
				svg {
					fill: #ffd023;
				}
			}
		}
		.body {
			.title {
				font-size: 16px;
				font-weight: 600;
				color: getColor(grey900);
				line-height: 160%; /* 22.4px */
			}
			.description {
				font-size: 14px;
				color: getColor(grey600);
				line-height: 160%; /* 22.4px */
			}
		}
	}
	.right {
		.time {
			font-size: 14px;
			color: getColor(grey600);
			line-height: 160%; /* 22.4px */
			white-space: nowrap;
		}
	}
}
