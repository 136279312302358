@mixin slider-btn-style {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 48px;
	height: 48px;
	background: #ffffff;
	border: 1px solid #f1f2f4;
	box-shadow: 2px 4px 24px rgba(26, 32, 44, 0.06);
	border-radius: 50%;
	cursor: pointer;
}
.sliderBtn {
	@include slider-btn-style;
	&:hover {
		background: var(--ltPrimary);
		color: #f1f2f4;
	}
}

.disabled {
	@include slider-btn-style;
	pointer-events: none;
	opacity: 0.4;
}

.hidden {
	display: none;
}
