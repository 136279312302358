.toastNote {
	width: 100%;
	display: flex;
	padding: 8px 12px;
	align-items: center;
	gap: 6px;
	border-radius: 4px;

	// Variants
	&.bgGhostWhite {
		background: #f5f8ff;
	}

	&.bgSecondary100Greyscale300 {
		border: 1px solid var(--ltGreyscale300);
		background: var(--ltSecondary300);
	}

	&.bgCosmicLatte {
		background: #fffae9;
	}

	&.bgSeaShell {
		background: #ffeded;
	}

	// Colors

}
