.popularPackageCard {
	display: flex;
	flex-direction: column;
	border: 1px solid var(--ltGreyscale200);
	border-radius: 10px;
	background: #fff;
	overflow: hidden;

	&_image {
		width: 100%;
		height: 100%;
	}

	&_content {
		background-color: #fff;

		.packageDetails {
			padding: 20px 20px 16px 20px;

			.title {
				line-height: 24.5px;
				margin-bottom: 24px;
			}

			.price {
				display: flex;
				flex-direction: column;
				align-items: center;

				& > p > span {
					line-height: 25.6px;
				}

				& p:nth-child(2) {
					margin-top: 16px;
					margin-bottom: 16px;
					line-height: 27px;
				}
			}

			.details {
				line-height: 22.4px;
			}
		}

		.packageTests {
			padding: 16px 20px 20px 20px;
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			gap: 8px;
		}

    .btn_container{
      padding: 0px 20px 20px 20px;
    }
	}
}
