.selectTestPatientCard {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	align-self: stretch;
	// border-top: 1px solid var(--ltGreyscale300)

	.selectPatient_counter {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 24px;
		.minus {
			display: flex;
			padding: 10px;
			align-items: center;
			justify-content: center;

			border-radius: 4px;
			border: 1px solid var(--ltGreyscale300);
			background: var(--ltWhite);
		}
		.count {
			display: inline-block;
			width: 1ch;
			text-align: center;
		}
		.plus {
			display: flex;
			padding: 10px;
			justify-content: center;
			align-items: center;

			border-radius: 4px;
			background: var(--ltPrimary);
		}
	}
}
