.orderCard_bottom {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 10px;
	padding: 12px 20px;

	.data_time {
		width: 70%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 10px;
	}

	.btn_wrap {
		width: 30%;
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
}

@media (max-width: 1400px) {
	.orderCard_bottom {
		.data_time {
			flex-direction: column;
		}
	}
}

/* SampleCollection Schedule Modal */
.sampleCollectionSchedule_modal {
	width: 100%;
	padding: 30px;
	min-width: 564px;
	max-width: 564px;
	max-height: 70vh;
	overflow-y: scroll;
}
