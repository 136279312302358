.vendorCard {
	width: 100%;
	display: flex;
	align-items: center;
	gap: 12px;
	padding: 12px 20px;

	.icon {
		width: 32px;
		height: 32px;
	}
}
