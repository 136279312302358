.selected_item {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 5px;
	padding: 12px 20px;
	border-radius: 6px;
	border: 1px solid var(--greyscale-300, #eeeff2);
	color: var(--ltPrimary);
	cursor: pointer;
	text-wrap: nowrap;
	margin-left: auto;
	max-height: 44px;

	/* p {
		font-size: 12px;
	} */

	&.fullWidth {
		width: 100%;
	}

	&.xs {
		padding: 3px 8px;
	}

	&.sm {
		padding: 8px 26px;
	}

	&.lg {
		padding: 12px 56px;
	}

	&.xl {
		padding: 16px 56px;
	}

	&.patient_1 {
		padding: 12px 26px;

		&.xs {
			padding: 3px 12px;
		}

		&.sm {
			padding: 8px 30px;
		}

		&.lg {
			padding: 12px 60px;
		}

		&.xl {
			padding: 16px 56px;
		}
	}

	&.active {
		border: 1px solid var(--ltPrimary);
		color: var(--ltPrimary);
	}
	&.error {
		border: 1px solid var(--ltErrorBase);
	}

	&.search {
		padding: 16px 20px;
		border-radius: 6px;
	}
}

@media (min-width: 768px) and (max-width: 1200px) {
	.selected_item {
		padding: 12px 13.2px;
		max-height: 39px;
	}

	.selected_item p {
		font-size: 12px;
	}

	.selected_item.patient_1 {
		padding: 12px 17.2px;
	}
	.selected_item.lg {
		padding: 12px 26px;
	}
	.selected_item.patient_1.lg {
		padding: 12px 30px;
	}
	.selected_item.sm {
		padding: 8px 10px;
	}
	.selected_item.patient_1.sm {
		padding: 8px 14px;
	}
	.selected_item.xs {
		padding: 3px 2px;
	}
	.selected_item.patient_1.xs {
		padding: 3px 2px;
	}
	.selected_item.xl {
		padding: 16px 26px;
	}
	.selected_item.patient_1.xl {
		padding: 16px 30px;
	}
}
