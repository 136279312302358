@mixin date {
	display: flex;
	width: 100%;
	max-width: 100px;
	padding: 42.5px 4px;
	flex-direction: column;
	align-items: center;
	gap: 4px;
	border-radius: 99px;
}

.dateSlot {
	@include date;
	border: 1px solid var(--ltGreyscale300);
	background: var(--ltGreyscale100);
}

.selected_dateSlot {
	@include date;
	color: var(--ltPrimary);
	border: 1px solid var(--Colors-Primary-base, #0e7673);
	background: var(--Colors-Primary-bg, #f1f7f7);
}
