/* Modal */

.dialogModal {
	border: none; /* Must for Dialog Modal */
	outline: none; /* Must for Dialog Modal */
	padding: 0; /* Must for Dialog Modal */
	margin: auto auto; /* Positioning Modal */
	border-radius: 10px;
	z-index: 10;
	width: fit-content;
	overflow-x: hidden;
	// overflow: hidden;
	// max-height: 700px;
	&::backdrop {
		background: rgba(0, 0, 0, 0.4);
	}

	&_body {
		background-color: #fefefe;
		border-radius: 10px;
	}

	// &.open {
	// 	display: block;
	// }

	// &::-webkit-scrollbar {
	// 	display: none;
	// }
}

// .dialog_head_line {
// 	height: 1px;
// 	max-height: 80vh;
// 	background: #e8f5f5;
// }

/* StateFull Modal */

.stateFullModal {
	display: none; /* Hidden by default */
	position: fixed; /* Stay in place */
	z-index: 10001;
	left: 0;
	top: 0;
	width: 100%;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.4);

	&_body {
		background-color: #fefefe;
		margin: 7% auto;
		width: fit-content;
		max-width: 700px;
		border-radius: 10px;
	}

	&.open {
		display: block;
	}

	&::-webkit-scrollbar {
		display: none;
	}
}
