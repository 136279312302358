.testCard_patient_btn {
	width: 140px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

@media (min-width: 768px) and (max-width: 1200px) {
	.testCard_patient_btn {
		width: 110px;
	}
}

.dialogModal_body_contents__cart {
	width: 100%;
	height: 100%;
	min-width: 280px;
}

.dialogModal_body_contents {
	width: 100%;
	height: 100%;
	min-width: 500px;
	max-width: 500px;
	// min-height: 50vh;
	// max-height: 60vh;
	position: relative;
	// padding: 20px;
	// display: flex;
	// flex-direction: column;

	.selectedTest {
		padding: 24px;
		position: relative;
	}

	.patientCount {
		padding: 16px 24px;
	}

	.noteInfo {
		padding: 16px 16px;
	}
	.labVendors {
		display: flex;
		flex-direction: column;
		padding: 12px 24px 24px 24px;

		.vendorList {
			max-height: 250px;
			display: flex;
			flex-direction: column;
			gap: 16px;
			overflow-y: scroll;
			// scrollbar-width: thin;
		}
	}
}

.dialogModal_footer {
	background-color: #ffffff;
	border-top: 1px solid var(--ltGreyscale300);
	display: flex;
	gap: 1rem;
	width: 100%;
	padding: 16px 24px 16px 24px;
	position: sticky;
	bottom: 0;
}
