@import '@/services/scss/variables.scss';
@import '@/services/scss/mixin.scss';

.accordion {
	border-bottom: 1px solid color(grey300);
	&:last-child {
		border-bottom: none;
	}
}

.contentClassName {
	padding: 0;
}
.icon {
	height: 40px;
	width: 40px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	&.collapse {
		color: getColor(grey900);
		background-color: getColor(grey300);
	}
	&.expand {
		color: getColor(primary);
		background-color: getColor(primary_bg);
	}
}
