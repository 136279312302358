.sideDrawer {
	position: fixed;
	right: 0;
	top: 0;
	background: #ffffff;
	height: 100%;
	width: 100%;
	max-width: 375px;
	transform: translateX(400px);
	transition: transform 0.5s ease;
	border: 1px solid var(--ltGreyscale300); 
	box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.10);
	z-index: 100;
	overflow-y: scroll;
	scroll-snap-type: y mandatory;
	&::-webkit-scrollbar {
		display: none;
	}

	&.open {
		transform: translateX(0);
		z-index: 99999;
		top: 0px;
	}

	.sideDrawer_head {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 8px 16px;
		border-bottom: 1px solid #eeeff2;
	}
}
