.textfield_wrap {
	width: 100%;
	padding: 12px;
	background: #fffae9;
	color: var(--ltGreyscale500);
	border-radius: 8px;
	border: 1px solid var(--ltGreyscale300);
	overflow: hidden;
	&.errorDark {
		border: 1px solid var(--ltError);
	}
}

.textfield {
	width: 100%;
	border: none;
	overflow: auto;
	outline: none;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	resize: none;
	background: inherit;
	color: var(--ltGreyscale500);
	font-size: 12px;
	&::placeholder {
		font-size: 12px;
		font-weight: 400;
		color: var(--ltGreyscale500);
		font-family: 'Inter', sans-serif;
	}
}
