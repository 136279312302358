.orderTestCard {
	display: flex;
	align-items: center;
	gap: 14px;

	.image {
		width: 80px;
		height: 80px;
    min-width: 80px;
		border-radius: 4px;
		border: 1px solid var(--ltGreyscale300);
		background: lightgray 50%;
		overflow: hidden;
		position: relative;

		.discount_chip{
			position: absolute;
			top: 0;
			left: 6px;
			z-index: 1;
		}
	}

	.description {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.price {
			margin-top: auto;
			display: flex;
			flex-direction: column;
			align-items: flex-end;
		}
	}
}
