.labPartnerCard {
	width: 100%;
	display: flex;
	padding: 10px;
	gap: 10px;
	border-radius: 6px;
	border: 1px solid var(--ltGreyscale300);
	background: var(--ltWhite);

	/* Card Shadow */
	box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.04);

	&:hover {
    cursor: pointer;
		border: 1px solid var(--ltPrimary);
	}

	&_icon {
		width: 100%;
		min-width: 100px;
		max-width: 100px;
		height: 100px;
		border-radius: 6px;
		border: 1px solid #eeeff2;
		padding: 4px;
		overflow: hidden;
	}

	&_content {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 10px;
	}
}
