.discountChid {
	&_test {
		display: flex;
		align-items: center;
		justify-content: center;

		& > p {
			width: fit-content;
			height: 20px;
			background: #fd6a6a;
		}
	}

	&_cart {
		width: 100%;
		max-width: 25px;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		& > div {
			width: 100%;
			text-align: center;
			margin-top: -2px;
			margin-bottom: -2px;

			& p {
				width: 100%;
				height: 100%;
				max-height: 20px;
				background: #2168de;
				font-size: 8px;
				font-weight: 600;
			}
		}
	}
}
