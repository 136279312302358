@import '@/services/scss/variables.scss';
@import '@/services/scss/mixin.scss';


.addReviewBlock {
    padding: 24px;

    .productCard {
        display: flex;
        background-color: var(--ltGreyscale100);
        padding: 8px 12px;
        border-radius: 10px;
       img {
            border: 1px solid var(--ltGreyscale300);
            background-color: var(--ltGreyscale200);
       }
    }
    .productInfo {
        padding-left: 16px;
        .productTitle {
            font-size: 14px;
            font-weight: 500;
            color: #344054;
            line-height: 27px;
        }
        .subTitle {
            font-size: 12px;
            font-weight: 400;
            color: #667085;
            line-height: 24px;
        }
    }
    .chip {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 34px;
        font-size: 12px;
        font-weight: 500;
        background-color: var(--ltGreyscale100);
        border: 1px solid var(--ltGreyscale300);
        padding: 8px 12px;
        border-radius: 6px;
        cursor: pointer;
        &.active {
            color: var(--ltPrimary);
            background-color: #F1F7F7;
            border: 1px solid var(--ltPrimary);
        }
    }
    .inputReview {
        margin-top: 16px;
    }
    .error {

    }
    .important {
        color: #1F2937;
        margin-top: 16px;
        padding: 12px;
        background: var(--ltGreyscale100);
        width: auto;
        font-size: 12px;
        font-weight: 500;
        border-radius: 8px;
    }
    .reviewText {
        position: absolute;
        bottom: 10px;
        right: 10px;
        font-size: 12px;
    }
    .errorMessage {
        color: red;
    }
}

.modalHeader {
    display: flex;
    flex-direction: column;
    .modalTitle {
        font-size: 20px;
        line-height: 30px;
    }
    .modalSubTitle {
        font-size: 14px;
        color: var(--ltGreyscale600);
        font-weight: 400;
    }
}