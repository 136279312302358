.overview {
	padding: 15px 20px;
	border-radius: 10px;
	border: 1px solid var(--Greyscale-300, #eeeff2);
	background: var(--Others-White, #fff);
	.description {
		img {
			width: 100%;
		}
	}
}
.title_img_wrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
	> div {
		display: flex;
		align-items: center;
		gap: 10px;
	}
	h6 {
		margin-bottom: 0;
	}
}
.img_wrapper {
	width: 38px;
	height: 38px;
	padding: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
	border: none;
	border-radius: 50%;
	background: var(--ltGreyscale200);
	img {
		color: var(--ltPrimary);
	}
}
.description {
	padding: 0 20px 0px 50px;
	line-height: 150%;
}
