@import '@/services/scss/variables.scss';
@import '@/services/scss/mixin.scss';


.riderInfo {
    display: flex;
    flex-direction: column;
    margin: 0;

    .delivere {
        font-size: 18px;
        margin-bottom: 20px;
        font-weight: 500;
    }

    .rider {
        display: flex;
        justify-content: space-between;
        .profile {
            display: flex;

            .image {
                margin-right: 16px;
                width: 62px;
                height: 62px;
                border-radius: 50%;
            }

            .name {
                display: flex;
                flex-direction: column;

                .nameText {
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 27px;
                    margin: 0;
                }

                .statusText {
                    color: var(--ltGreyscale600, #718096);
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }
        .riderButton {

        }
    }
}
.reviewBlock {
    margin-top: 16px;
}