.orderCard {
	border-radius: 10px;
	border: 1px solid var(--ltGreyscale300);
	overflow: hidden;
	transition: border 0.15s ease;
	cursor: pointer;
	background: #fff;
}
.orderCard:hover {
	border: 1px solid var(--ltPrimary);
}
.orderCard_icon {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 50px;
	height: 50px;
	background: #e8f5f5;
	border-radius: 50%;
	color: var(--ltPrimary);
}
.orderCard_icon > i {
	color: #fe964a;
}
.orderCard_top {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 14px 20px;
	color: var(--ltGreyscale600);
	border-bottom: 1px solid var(--ltGreyscale300);
	background: #f9fafb;
}
.orderCard_body {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 15px 20px;
	border-bottom: 1px solid var(--ltGreyscale300);
}
.orderCard_bottom {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 12px 20px;
}
.orderCard_body_right {
	text-align: right;
}
