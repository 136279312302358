.checkboxOverride {
	position: relative;
	input[type='checkbox'] {
		opacity: 0;
	}
	input[type='checkbox']:checked {
		& + label {
			background: var(--ltPrimary);
			opacity: 1;
			&:after {
				opacity: 1;
			}
		}
	}
}

.label {
	border: 2px solid #a0aec0;
	cursor: pointer;
	height: 25px;
	width: 25px;
	left: 0;
	position: absolute;
	top: 0;
	border-radius: 3px;
	&:after {
		border-style: none none solid solid;
		content: '';
		height: 5px;
		width: 9px;
		left: 5px;
		top: 5px;
		opacity: 0;
		position: absolute;
		-ms-transform: rotate(-45deg);
		-webkit-transform: rotate(-45deg);
		transform: rotate(-45deg);
		color: #fff;
	}
}
.sm {
	width: 22px;
	height: 22px;
	&:after {
		height: 3px;
		width: 7px;
		left: 4px;
		top: 4px;
	}
}
