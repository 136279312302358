.bookAppointment {
	margin-top: 30px;
	margin-bottom: 20px;
	display: flex;
	flex-direction: column;
	gap: 30px;

	&_action {
		.btn_wrap {
			width: 100%;
			max-width: 250px;
		}
	}
}
