.input_wrap {
	width: 100%;
	border-radius: 10px;
}
.input_field {
	position: relative;
	display: flex;
	align-items: center;
}
.input {
	display: flex;
	flex: 1;
	outline: none;
	border: none;
	border-radius: 10px;
	font-size: 14px;
	font-weight: 400;
	color: var(--ltGreyscale600);
	width: 100%;
	padding: 16px;
	max-height: 44px;

	&::placeholder {
		font-weight: 400;
		font-size: 16px;
		color: var(--ltGreyscale500);
		text-overflow: ellipsis;
	}

	&:placeholder-shown {
		text-overflow: ellipsis;
	}

	&.outlined {
		border: 1px solid var(--ltGreyscale300);
	}

	&.outlined_bg {
		background: var(--ltGreyscale200);
	}
}

.focus {
	&:focus {
		border: 1px solid var(--ltPrimary);
	}
}

.no_focus {
	&:focus {
		border: none;
	}
}
.leftIconInput {
	padding: 16px 16px 16px 48px;
}
.rightIconInput {
	padding: 16px 48px 16px 16px;
}
.leftIcon {
	position: absolute;
	left: 16px;
	top: 50%;
	transform: translateY(-50%);
}
.rightIcon {
	position: absolute;
	right: 16px;
	top: 50%;
	transform: translateY(-50%);
}
