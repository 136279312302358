.testCard {
	width: 100%;
	height: 100%;
	background: #ffffff;
	border: 1px solid var(--ltGreyscale300);
	border-radius: 6px;
	transition: border 0.15s ease;
	box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.04);
	cursor: pointer;

	&:hover {
		border: 1px solid var(--ltPrimary);
	}

	&_top {
		display: flex;
		align-items: center;
		gap: 10px;
		// flex: 0.7;
		padding: 10px;

		&_icon {
			width: 100%;
			min-width: 114px;
			max-width: 114px;
			height: 114px;
			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;

			.badge {
				position: absolute;
				bottom: 12px;
				left: 0;
			}

			& > i {
				color: #fe964a;
			}

			.unavailable_icon {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: center;

				border-radius: 6px;
				border: 1px solid var(--Greyscale-300, #eeeff2);
				background: linear-gradient(0deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.8) 100%);

				.text_wrap {
					display: flex;
					width: 100%;
					height: 34px;
					padding: 4px 17px;
					justify-content: center;
					align-items: center;

					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					background: rgba(31, 41, 55, 0.7);

					& > span {
						font-size: 16px;
						font-weight: 400;
					}
				}
			}
		}

		&_content {
			width: 100%;
			display: flex;
			justify-content: space-between;

			.contents {
				display: flex;
				flex-direction: column;
				flex: 1;
				p {
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 1;
					overflow: hidden;
				}
				div {
					&:last-of-type {
						span {
							color: var(--ltGreyscale600);
						}
					}
				}
			}
		}

		.vendor_image {
			width: 28px;
			height: 28px;
			padding: 2px 2px;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 3px;
			border: 1px solid var(--ltGreyscale300);
			background: #fff;
		}
	}

	&_bottom {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 10px;
	}
}

.testCard_left_btn {
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: var(--ltPrimary);
}

.testCard_patient {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 1rem;
	text-wrap: nowrap;
	p {
		display: flex;
		align-items: center;
		line-height: 150%;
	}
	& > p {
		& > span {
			display: inline-block;
			height: 44px;
			padding-right: 16px;
			border-left: 1px solid #eeeff2;
		}
	}
}
.testCard_patient_btn {
	width: 140px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}
.testDetailsHeadCard_text_bottom_disc {
	display: flex;
	align-items: center;
	gap: 10px;
	& > p {
		color: #111827;
		font-weight: 600;
	}
	& > span {
		font-weight: 400;
		color: #718096;
		text-decoration: line-through;
	}
}
.testDetailsHeadCard_text_bottom_disc_booked {
	display: flex;
	align-items: center;
	gap: 5px;
	color: #323b49;
	i {
		color: #e6bb20;
	}
}
.bookTest_btn_container {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
}
