.drPaymentOverviewCard {
	border: 1px solid var(--ltGreyscale300);
	border-radius: 8px;
	background: #fff;
	box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.04);

	display: flex;

	&_overview {
		display: flex;
		gap: 10px;
		padding: 15px;

		.image {
			width: 105px;
			height: 105px;
			min-width: 105px;
			border: 1px solid var(--ltGreyscale300);
			border-radius: 6px;
			overflow: hidden;
		}

		.bioData {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			gap: 10px;

			div {
				&:last-child {
					margin-top: -5px;
				}
			}

			.name {
				display: flex;
				align-items: center;
				gap: 5px;
			}

			// p {
			// &:first-child {
			// 	line-height: 24px;
			// }
			// &:nth-child(2),
			// &:nth-child(3) {
			// 	line-height: 22.4px;
			// }
			// }
		}
	}

	&_patientQueue {
		width: 180px;
		margin-left: auto;
		border-left: 1px solid var(--ltGreyscale300);
    padding: 15px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		.count {
			width: 35px;
			height: 35px;
			background: var(--ltPrimary);
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		& > p {
			line-height: 160%; /* 22.4px */
		}

		// div{
		//   display: flex;
		//   gap: 10px;
		//   align-items: center;
		// }
	}
}
