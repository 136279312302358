.cancel_reason_container {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 16px;
	position: relative;
}

// .lists_wrap {
// 	display: flex;
// 	flex-direction: column;
// 	gap: 5px;
// }

// .listItem {
// 	display: flex;
// 	align-items: center;
// 	justify-content: flex-start;
// }
