.labTestCardRow {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 24px;
}

@media screen and (max-width: 1620px) {
	.labTestCardRow {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media screen and (max-width: 1200px) {
	.labTestCardRow {
		grid-template-columns: repeat(1, 1fr);
	}
}

.labTest_container_head {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 30px;
}

.labTest_container_head {
	& > a {
		color: var(--ltGreyscale900);
		cursor: pointer;
		font-size: 20px;
		font-weight: 700;
		&:hover {
			color: var(--ltPrimary);
		}
	}
}
