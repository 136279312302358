@import '@/services/scss/variables.scss';
@import '@/services/scss/mixin.scss';

.medicine__list {
	width: 100%;
	.medicine_item {
		border-bottom: 1px solid getColor(grey300);
		.medicine {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding-inline: 20px;
			padding: 20px;
			.items_left {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
				gap: 20px;
				max-width: 70%;
				min-width: 70%;
				.item__image {
					position: relative;
					width: 117px;
					height: 117px;
					min-width: 117px;
					min-height: 117px;
					border-radius: 9px;
					border: 1px solid getColor(grey300);
					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
						border-radius: 9px;
					}
					.item__stock {
						position: absolute;
						top: 50%;
						width: 100%;
						padding-inline: 20px;
					}
					.item__stock__text {
						display: flex;
						justify-content: space-between;
						align-items: center;
						background-color: #718096;
						border-radius: 3px;
						display: flex;
						justify-content: center;
						align-items: center;
						font-size: 10px;
						color: #fff;
						font-weight: 600;
						padding: 2px 5px;
					}
				}

				.item__details {
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					align-items: flex-start;
					width: 100%;
					min-width: 100%;
					h1 {
						overflow: hidden;
						text-overflow: ellipsis;
					}
				}
			}
			.right {
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				align-items: flex-end;
			}
		}
	}
	.medicine_item {
		&:last-child {
			border-bottom: none;
		}
	}
}

.tag_input {
	position: relative;
	width: 100%;
	input[type='color'] {
		width: 30px;
		height: 30px;
		border-radius: 50%;
		border: 1px solid getColor(grey300);
		padding: 10px;
		cursor: pointer;
		position: absolute;
		top: 20%;
		left: 10px;
		&::-webkit-color-swatch-wrapper {
			padding: 0;
		}

		&::-webkit-color-swatch {
			border: none;
			border-radius: 50%;
		}
	}
	.tag_input__input {
		width: 100%;
		border-radius: 10px 0 0 10px;
		border: 1px solid getColor(grey300);
		padding: 20px 20px 20px 50px;
	}
}

.product_tag {
	display: flex;
	flex-direction: column;
	gap: 10px;
	margin-top: 20px;
	.product_tag__item {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		gap: 10px;
		padding: 5px 10px;
		border-radius: 10px;
		background-color: getColor(grey200);
		label {
			color: getColor(grey900);
			padding: 10px;
		}
	}
}
.discount {
	position: absolute;
	background-image: url('../../../../../assets/svg/pdiscount.svg');
	background-size: contain;
	background-repeat: no-repeat;
	height: 30px;
	width: 30px;
	top: 0;
	left: 10px;
	span {
		position: relative;
		font-size: 10px;
		color: white;
		font-weight: 600;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		width: 100%;
		text-align: center;
	}
}
