/* Responsive table wrapper */
@import '@/services/scss/variables.scss';
@import '@/services/scss/mixin.scss';

.tableContainer {
    width: 100%;
    overflow-x: auto; /* Enables horizontal scrolling for tables */
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on mobile devices */
    border: 1px solid #ddd; /* Optional: adds a border around the table container */
    border-radius: 6px; /* Adds rounded corners */
    margin-bottom: 1rem; /* Space below the table */
    background-color: #fff; /* White background for contrast */
}

/* General table styling */
.table {
    width: 100%;
    border-collapse: collapse; /* Removes spacing between cells */
    border-spacing: 0; /* Ensures no spacing between cells */
    font-size: 14px; /* Default font size */
    text-align: left; /* Aligns text to the left */
}

/* Table headers */
.tableHeader {
    background-color: #f8f9fa; /* Light gray background for headers */
    font-weight: 600; /* Bold font for headers */
    color: #333; /* Dark text color */
    text-align: center; /* Center-align text in headers */
    border: 1px solid #ddd; /* Border for headers */
    padding: 6px; /* Padding inside header cells */
    text-transform: uppercase; /* Uppercase text for a modern look */
}

/* Table cells */
.tableCell {
    border: 1px solid #ddd; /* Border for table cells */
    padding: 6px; /* Padding inside cells */
    text-align: center; /* Center-align text in cells */
    word-wrap: break-word; /* Break long words */
    white-space: normal; /* Prevent overflow for text */
    color: #555; /* Subtle text color */
}

/* Alternating row background for readability */
.evenRow {
    background-color: #f9f9f9; /* Light gray background for even rows */
}

.oddRow {
    background-color: #fff; /* White background for odd rows */
}

/* Hover effect for rows */
.rowHover:hover {
    background-color: color(primary_bg);
    transition: background-color 0.2s ease-in-out; /* Smooth transition for hover */
    cursor: pointer; /* Indicates interactivity for the entire row */
}

/* Mobile-specific adjustments */
@media (max-width: 768px) {
    .table {
        font-size: 12px; /* Reduce font size for smaller screens */
    }

    .tableHeader,
    .tableCell {
        padding: 6px; /* Smaller padding */
    }

    /* Stack table rows on small screens */
    .tableResponsive .table {
        display: block;
        width: 100%;
        overflow-x: auto;
        white-space: nowrap; /* Prevent table wrapping */
    }

    .row {
        display: block;
        margin-bottom: 1rem; /* Adds space between rows */
    }

    .tableCell {
        display: block;
        text-align: left; /* Align text to the left for stacked rows */
        padding-left: 50%; /* Indent text to the middle */
        position: relative;
    }

    .tableCell::before {
        content: attr(data-label); /* Adds labels to cells */
        position: absolute;
        left: 0;
        top: 0;
        width: 50%;
        padding-left: 10px;
        font-weight: bold;
        color: #333; /* Dark text for labels */
        white-space: nowrap; /* Prevent label wrapping */
    }

    .tableHeader {
        display: none; /* Hide table headers on mobile */
    }
}

/* Additional utility classes */
.tableAuto {
    table-layout: auto; /* Default table layout */
}

.tableFixed {
    table-layout: fixed; /* Fixed table layout for equal-width columns */
}
