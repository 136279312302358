.switchLabVendor {
	position: relative;
	.vendorLists {
    padding: 16px 20px;
		display: flex;
		flex-direction: column;
		gap: 16px;
    max-height: 416px;
		overflow: auto;
	}

	.action_btns {
		position: sticky;
		bottom: 0;
		background: white;
		padding: 16px 20px;
		border-top: 1px solid var(--ltGreyscale300);
		border-radius: 0px 0px 10px 10px;
	}
}
