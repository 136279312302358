.progressBarContainer {
	width: 100%;
	height: 8px;
	background-color: var(--ltGreyscale300);
	border-radius: 50px;

	.fill {
		height: 100%;
		border-radius: inherit;
		text-align: right;
		transition: width 1s ease-in-out;
	}

	.label {
		padding: 5px;
		color: white;
	}
}
