@import '@/services/scss/variables.scss';

.drawer__body__items {
	margin-top: 16px;
	box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.1);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	border: 1px solid getColor(grey300);
	border-radius: 6px;
	margin-bottom: 16px;
}

.shopping_cart_offer_card {
	margin: 0px 0;
	border: 0.5px dashed getColor(primary_base);
	background-color: #e7f1f1;
	border-radius: 6px;
	padding: 10px;
	& > div {
		// @include itemsCenter;
		font-size: 12px;
		font-weight: 600;
		color: getColor(primary_base);

		svg {
			margin-right: 10px;
			width: 24px;
			height: 24px;
		}
	}
}
.prescriptions {
	display: flex;
	align-items: center;
	gap: 10px;
}
.payment {
	border-radius: 6px;
	border: 1px solid getColor(grey300);
	background: #fff;

	.payment__item {
		padding-inline: 17px;
		padding-block: 20px;
		border-bottom: 1px solid getColor(grey300);
		margin-bottom: 14px;
	}
	.payment__item_sep {
		border-bottom: 2px dashed getColor(grey300);
	}
}
.delivery_options {
	padding: 10px;

	.disabled {
		opacity: 0.8;
	}
	.common {
		// @include flexBetween;

		p {
			font-size: 14px;
			margin: 0;
		}

		.option_list {
			display: flex;
			cursor: pointer;

			svg {
				margin-right: 5px;
			}
			.type {
				// @include flexColumn;
				.text {
					font-size: 12px;
					color: $greyD;
					font-weight: 400;
					margin: 5px 0;
				}
				.error {
					color: $error;
					font-size: 12px;
					font-weight: 400;
					margin-bottom: 5px;
				}
			}
		}
	}

	.express {
		margin-top: 10px;
	}

	.select_date_time {
		font-size: 12px;
		padding-block: 10px;
		color: $primary;
		justify-content: space-between;
	}
}

.checkout_wraper {
	padding: 10px 16px;
	width: 100%;
	z-index: 9999;
	// width: 380px;
	height: 75px;
	background-color: white;

	align-items: center;
	box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.1);
	.checkout {
		display: flex;
		justify-content: space-between;
		align-items: center;
		background-color: getColor(primary);
		border-radius: 5px;
		.checkout__left {
			display: flex;
			align-items: center;
			gap: 10px;
			padding: 10px;
		}
	}
}

.delivery_type_regular {
	p {
		background-color: white !important;
		color: getColor(grey900) !important;
		padding: 0px;
		svg {
			width: 20px;
			height: 20px;
			background-color: getColor(primary);
			border-radius: 50%;
			padding: 5px;
		}
		font-size: 12px;
	}
}
.delivery_type_express {
	p {
		background-color: white !important;
		color: getColor(grey900) !important;
		padding: 0px;
		svg {
			width: 20px;
			height: 20px;
			background-color: getColor(grey900);
			border-radius: 50%;
			padding: 5px;
		}
		font-size: 12px;
	}
}

.input_notes {
	background-color: getColor(secondary100);
	margin-top: 10px;
}

.discount {
	color: #fff;
	background-image: url('../../../assets/svg/discount.svg');
	background-size: 100% 100%;
	background-repeat: no-repeat;
	font-weight: 600;
	padding: 0px 24px;
	font-size: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	margin-left: 5px;
	min-width: 65px;
}

.cardImage {
	border-radius: 6px;
	border: 1px solid getColor(grey300);
}

.outofstock {
	position: absolute;
	top: 40%;
	width: 100%;
	padding-inline: 20px;
}
.stocks_message {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #718096;
	border-radius: 3px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 10px;
	color: #fff;
	font-weight: 600;
	padding: 2px 5px;
}

.stickyFooter {
	position: sticky;
	bottom: 0;
	background: #fff;
	background-color: #fff;
	width: 100%;
	z-index: 10;
}
