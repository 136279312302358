// Category Section

.health_concern_categorySection {
	/* margin-bottom: 30px; */
	/* background: #eef5f6; */
	padding: 30px 0px;
	background: #ffffff;
}
.life_style_categorySection {
	// margin-bottom: 30px;
	background: #ffffff;
}
.vital_organs_categorySection {
	// margin-bottom: 30px;
	// background: #eef5f6;
	// max-height: 332px;
	padding: 30px 0px;
	background: #ffffff;
}
.checkup_men_categorySection {
	// margin-bottom: 30px;
	background: #ffffff;
}
.checkup_women_categorySection {
	// background: #eef5f6;
	background: #ffffff;
	padding: 30px 0px;
}

.popular_health_checkup_categorySection {
	// margin-bottom: 30px;
	background: #ffffff;
}

// ===================

.categorySection_head {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 30px;
	& > div {
		& > p {
			color: var(--ltGreyscale600);
		}
	}
}
.categorySection_head_slidericon {
	display: flex;
	align-items: center;
	gap: 24px;
}
.categorySection_cardrow {
	display: flex;
	justify-content: space-between;
	gap: 24px;
	width: 100%;
	overflow-x: scroll;
	&::-webkit-scrollbar {
		visibility: hidden;
	}
}
.categorySection_cardrow_eplipses {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 24px;
	margin-top: 60px;
}

// ========= Swiper =========
.categorySection_swiper_wrap {
	padding: 16px 0;
	margin: -16px 0;
}
