.SelectLabVendorCard {
	width: 100%;
	// height: 100%;
	min-width: 400px;
	max-width: 500px;
	display: flex;
	padding: 16px;
	align-items: center;
	gap: 12px;
	align-self: stretch;
	cursor: pointer;

	border-radius: 6px;
	border: 1px solid var(--ltGreyscale300);
	background: var(--ltWhite);
	box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.04);

	.labVendorContainer {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 12px;

		.icon {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 60px;
			min-width: 60px;
			height: 60px;
			padding: 4px;
			border-radius: 6px;
			background: var(--Colors-Primary-bg, #f1f7f7);
			overflow: hidden;
		}

		.details {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;

			.price {
				display: flex;
				flex-direction: column;
				align-items: flex-end;
			}
		}
	}
}

.__active {
	border: 1px solid var(--ltPrimary);
}

.__hover {
	&:hover {
		border: 1px solid var(--ltPrimary);
	}
}
