@import '@/services/scss/variables.scss';
@import '@/services/scss/mixin.scss';

.codeInput {
	height: 44px;
	width: 44px;
	border: 1px solid #e0e0e0;
	border-radius: 5px;
	text-align: center;
	font-size: 16px;
	//placeholder
	&::placeholder {
		text-align: center;
		display: flex;
		align-items: center;
		color: getColor(grey300);
	}
}
.absulate_prefix {
	position: absolute;
	left: 0;
	padding-left: 15px;
	color: getColor(grey900);
	font-weight: 400;
	display: flex;
	align-items: center;
	height: 100%;
}
.prefix {
	color: getColor(grey900);
	font-weight: 400;
	display: flex;
	align-items: center;
	height: 100%;
}

.input {
	width: 100%;
	height: 50px;
	border-radius: 10px;
	border: 1px solid getColor(grey300);
	color: getColor(grey600);
	font-size: 16px;

	&::placeholder {
		color: getColor(grey300);
	}
}
