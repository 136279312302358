.descriptionCard {
	border-radius: 12px;
	border: 1px solid var(--ltGreyscale300);
	background: var(--ltWhite);

	display: flex;
	flex-direction: column;
	align-items: center;

	.empty {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 16px 20px;
	}

	.available {
		width: 100%;
	}
}
