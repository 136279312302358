.orderDetailsCard {
	display: flex;
	flex-direction: column;
	gap: 20px;
}
.orderDetails {
	border-radius: 6px;
	border: 1px solid var(--ltGreyscale300);
	overflow: hidden;
	background: #fff;
}
.orderDetailsCard_heading {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 20px;
}
.orderDetailsCard_lists {
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding: 20px;
	height: 100%;
	border-bottom: 1px solid var(--ltGreyscale300);
}
.orderDetailsCard_lists_text {
	display: flex;
	gap: 12px;
	align-items: center;
	span {
		color: var(--ltPrimary);
	}
}
.orderDetailsCard_bottom_note {
	padding: 10px 20px;
	background: var(--ltGreyscale200);
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.orderDetailsCard_bottom {
	> p {
		padding: 15px 20px;
	}
}
.order_details_item_smaple_discount {
	display: flex;
	align-items: center;
	gap: 10px;
}
.sample_collection_fee_container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 5px;
}
.sampleCollectionAddress {
	border-radius: 6px;
	border: 1px solid var(--Greyscale-300, #eeeff2);
	background: var(--Others-White, #fff);
	box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.04);
}
.toastContainer {
	border-radius: 6px;
	border: 1px solid var(--Greyscale-300, #eeeff2);
}
.orderDetailsCard_action {
	display: flex;
	flex-direction: column;
	gap: 16px;
	padding: 16px;
	padding-top: 0px;
}
.paymentDetails {
	border-radius: 6px;
	border: 1px solid var(--Greyscale-300, #eeeff2);
	background: var(--Others-White, #fff);
	box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.04);
	overflow: hidden;
}
.orderCancelReason {
	border-radius: 6px;
	border: 1px solid var(--Greyscale-300, #eeeff2);
	background: var(--Others-White, #fff);
	box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.04);

	& p {
		word-wrap: break-word;
		word-break: break-all;
	}
}
