// AddEdit Bio Modal

.addEditBioModal {
	width: 100%;
	min-width: 400px;
	max-width: 640px;

	&_body {
		padding: 20px;
	}

	.btn_action {
		width: 100%;
		border-top: 1px solid var(--ltGreyscale300);
		background: var(--ltWhite);
		border-radius: 0px 0px 10px 10px;
		padding: 20px;
		position: sticky;
		bottom: 0;
	}
}
