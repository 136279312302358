@import '@/services/scss/variables.scss';
@import '@/services/scss/mixin.scss';

.productCardBlock {
    border: 1px solid var(--ltGreyscale300);
    border-radius: 10px;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    margin-bottom: 24px;
    .orderInfo {

        // padding: 20px;
        .orderInfoSub {
            padding: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .orderId {
                font-size: 18px;
                font-weight: 600;
                color: var(--ltGreyscale700);
                margin-bottom: 0;

                .orderNumber {
                    color: var(--ltPrimary);
                }
            }

            .viewProduct {
                display: flex;
                font-size: 18px;
                font-weight: 500;
                color: var(--ltPrimary);
                cursor: pointer;
                gap: 14px;
            }
        }

        .viewProductList {
            height: auto;
            opacity: 1;
            transition: opacity 0.3s ease, height 0.3s ease;
            overflow: hidden;
            padding: 20px;
            border-top: 1px solid var(--ltGreyscale300);
            .viewProductItem {
                border-bottom: 1px solid var(--ltGreyscale300);
                padding: 20px 0;
                &:last-child {
                    border-bottom: none;
                    padding-bottom: 0;
                }
                &:first-child {
                    padding-top: 0;
                }
            }
        }
        .reviewList {
            padding: 0 20px 20px 20px;
        }
        .hideViewProductList {
            height: 0;
            opacity: 0;
            transition: opacity 0.3s ease, height 0.3s ease;
            overflow: hidden;
           
        }

        .purchasedDate {
            font-size: 16px;
            color: var(--ltGreyscale600);
        }

        .riderList {
            border-top: 1px solid var(--ltGreyscale300);
            padding: 20px;
        }
    }
}