.featureHighlights {
	display: flex;
	align-items: center;
	padding: 24px;
	gap: 24px;

	border-radius: 6px;
	border: 1px solid var(--ltGreyscale300);
	background: var(--ltWhite);
	box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.04);
}
