/* 
.radio_wrapper {
  display: flex;
  align-items: center;

.radio_wrapper label {
  font-size: 14px;
  color: getColor(grey700);
  margin-bottom: 0;
}
.radio_wrapper .radio {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid color(grey300);
  border-radius: 6px;
  margin-right: 10px;
  position: relative;
  outline: none;
  cursor: pointer;
}
.radio_wrapper .radio:checked {
  background-color: getColor(primary);
  border-color: getColor(primary);
}
.radio_wrapper .radio:checked:before {
  content: "";
  position: absolute;
  left: 72%;
  top: 40%;
  transform: translateX(-369%) rotate(133deg);
  height: 7px;
  width: 2px;
  background-color: white;
}
.radio_wrapper .radio:checked:after {
  content: "";
  position: absolute;
  left: 66%;
  top: 40%;
  transform: translateX(-48%) rotate(-50deg);
  height: 2px;
  width: 12px;
  background-color: white;
} */
.list {
	list-style: none;
	&::-webkit-scrollbar {
		width: 5px;
		height: 5px;
	}
	&::-webkit-scrollbar-thumb {
		border-radius: 20px;
	}
	.category_list_container {
		&:last-child {
			border-bottom: none;
		}
	}
}
.category_list_container {
	padding-bottom: 10px;
	border-bottom: 1px solid var(--Greyscale-300, #eeeff2);
}
.category_list {
	padding-left: 10px;
	overflow-y: scroll;
	.listItem {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 10px 0px 0px 10px;
	}
}
.category_list_lg {
	height: 180px;
}
.category_list_md {
	height: 150px;
}
.category_list_sm {
	height: 120px;
}
