// Section main Container
.availableDoctors {
	margin-bottom: 30px;
	background-color: #f2f9f9;
}

.labTestCardRow {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 24px;
}

@media screen and (max-width: 1340px) {
	.labTestCardRow {
		grid-template-columns: repeat(1, 1fr);
	}
}

.labTest_container_head {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 30px;
}

.labTest_container_head > a {
	color: var(--ltGreyscale900);
	cursor: pointer;
	font-size: 20px;
	font-weight: 700;
}

.labTest_container_head > a:hover {
	color: var(--ltPrimary);
}

/* Instant Consultation Card Lists */

.health_concern_categorySection {
	margin-bottom: 30px;
	padding: 30px 0px;
	background: #eef5f6;
	/* background: #ffffff; */
}

.life_style_categorySection {
	margin-bottom: 30px;
	/* background: rgba(29, 160, 153, 0.05); */
}

.vital_organs_categorySection {
	margin-bottom: 30px;
	padding: 30px 0px;
	background: #eef5f6;
	max-height: 332px;
	/* background: #ffffff; */
}

.checkup_men_categorySection {
	margin-bottom: 30px;
	/* background: rgba(29, 160, 153, 0.05); */
}

.checkup_women_categorySection {
	background: #eef5f6;
	/* background: #ffffff; */
	padding: 30px 0px;
}

.categorySection_head {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 30px;
	padding-top: 30px;
}

.categorySection_head > div > p {
	color: var(--ltGreyscale600);
}

.categorySection_head_slidericon {
	display: flex;
	align-items: center;
	gap: 24px;
}

.categorySection_cardrow {
	display: flex;
	justify-content: space-between;
	gap: 24px;
	width: 100%;
	overflow-x: scroll;
}

.categorySection_cardrow::-webkit-scrollbar {
	visibility: hidden;
}

.categorySection_cardrow_eplipses {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 24px;
	margin-top: 60px;
}

.button_wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 30px;
	padding-bottom: 30px;
}


// Slider Button
/* Instant Consultation Card Lists */
.sectionHeader {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;

	& > div > p {
		color: var(--ltGreyscale600);
	}

	.sliderButtons_wrapper {
		display: flex;
		align-items: center;
		gap: 24px;
	}

	.showMore_text {
		line-height: 28px;
	}
}
