.drRatingInput {
	border-radius: 6px;
	border: 1px dashed var(--ltWarningDark);
	background: var(--ltWhite);

	padding: 20px;
	display: flex;
  align-items: center;
  justify-content: center;
	flex-direction: column;
	gap: 12px;
}
