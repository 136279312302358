@import '@/services/scss/variables.scss';
@import '@/services/scss/mixin.scss';

.items__item {
	display: flex;
	padding-inline: 10px;
	padding-block: 10px;
	border-bottom: 1px solid getColor(grey300);
	flex-direction: column;
	&:last-child {
		border-bottom: none;
	}
	.items__left {
		display: flex;

		.item__image {
			height: 84px;
			width: 84px;
			border-radius: 10px;
			position: relative;
			img {
				height: 84px;
				width: 84px;
				border-radius: 10px;
				object-fit: contain;
				border: 1px solid color(grey300);
			}
		}
		.item__details {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			height: 100%;
			margin-left: 16px;
			gap: 4px;
			min-height: 84px;
			width: 60%;

			.item_name {
				font-size: 12px;
				font-weight: 500;
				color: getColor(grey900);
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				max-width: 240px;
				text-decoration: none;
			}
		}
	}
	.tems__right {
		display: flex;
		flex-direction: column;
		gap: 8px;
		align-items: flex-end;
		height: 84px;
		//flex end
	}
}

.cardImage {
	height: 80px; // Smaller image
	width: 80px; // Smaller image
	border-radius: 9px;
}

.outofstock {
	position: absolute;
	top: 40%;
	width: 100%;
	padding-inline: 10px;
}
.stocks_message {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #718096;
	border-radius: 3px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 10px;
	text-wrap: nowrap;
	color: #fff;
	font-weight: 600;
	padding: 2px 5px;
}
