.switchLabConfirmationCard {
	position: relative;
	width: 500px;

	.contents {
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		padding: 54px 16px 24px 24px;

		.image {
			width: 60px;
			height: 60px;
			padding: 4px;
			border-radius: 6px;
			border: 2px solid var(--Greyscale-300, #eeeff2);
			background: var(--Others-White, #fff);
		}
	}

	.action_btns {
		display: flex;
		align-items: center;
		gap: 12px;
		position: sticky;
		bottom: 0;
		background: white;
		padding: 24px 24px;
		border-top: 1px solid var(--ltGreyscale300);
		border-radius: 0px 0px 10px 10px;
	}
}
