/* Instant Consultation Card Lists */
.sectionHeader {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;

	& > div > p {
		color: var(--ltGreyscale600);
	}

	.sliderButtons_wrapper {
		display: flex;
		align-items: center;
		gap: 24px;
	}

	.showMore_text {
		line-height: 28px;
	}
}
