.selectLabTestCard {
	display: flex;
	align-items: center;
	gap: 14px;

	.icon_wrap {
		width: 100%;
		min-width: 100px;
		max-width: 100px;
		height: 100px;
		border-radius: 6px;
		overflow: hidden;
		border: 1px solid #eeeff2;
		background: #f1f7f7;
	}

	.content {
		width: 100%;
	}
}
