.drRatingInputModal {
	padding: 30px;

	display: flex;
	flex-direction: column;
	justify-content: center;

	&_rating {
		margin-bottom: 30px;

		.image {
			width: 120px;
			height: 120px;
			border-radius: 50%;
			background: var(--ltGreyscale300);
			margin: auto;

			margin-bottom: 20px;
		}
	}
}
