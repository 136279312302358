@mixin time {
	width: 139px;
	height: 48px; /* Set a fixed height */
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 13px 32px;
	gap: 10px;
	border-radius: 8px;
}

.appointmentTimeSlots {
	.timeSlots_wrapper {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 32px;
		align-self: stretch;

		.timeSlots_slot {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 20px;
			align-self: stretch;

			&_times {
				display: flex;
				align-items: center;
				gap: 24px;
				align-self: stretch;
				flex-wrap: wrap;

				.timeSlot {
					@include time;
					border: 1px solid var(--ltGreyscale300);
					background: var(--ltGreyscale100);
				}

				.selected_timeSlot {
					@include time;
					border: 1px solid var(--ltPrimary);
					background: var(--Colors-Primary-bg, #f1f7f7);
				}
			}
		}
	}
}
