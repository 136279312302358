@import '@/services/scss/variables.scss';
@import '@/services/scss/mixin.scss';

.imageUpload {
	display: flex;
	align-items: center;
	gap: 16px;

	.box {
		width: 100px;
		height: 100px;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 2px dotted #eeeef2;
		border-radius: 8px;
		cursor: pointer;
		text-align: center;
		position: relative;
		transition: border-color 0.3s;

		&.uppercase {
			text-transform: uppercase;
		}
	}

	.icon {
		font-size: 2rem;
		color: #bbb;
		display: flex;
		flex-direction: column;
		align-items: center;

		.count {
			font-size: 12px;
			color: #0e7673;
			margin-top: 0.25rem;
		}
	}

	.Preview {
		display: flex;
		flex-wrap: wrap;
		gap: 16px;
		align-items: center;

		.PreviewItem {
			position: relative;
			width: 100px;
			height: 100px;
			display: flex;
			align-items: center;
			justify-content: center;

			.RemoveBtn {
				display: flex;
				align-items: center;
				justify-content: center;
				position: absolute;
				top: -12px;
				right: -12px;
				border: none;
				cursor: pointer;
				padding: 5px;
				background-color: var(--ltGreyscale600);
				border-radius: 100%;
			}

			.removeIcon {
				color: white;
			}

			.thumbnail {
				width: 100%;
				height: 100%;
				object-fit: cover;
				border-radius: 4px;
			}
		}
	}
	.errorMessage {
		color: red;
		font-size: 12px;
		margin-bottom: 10px;
	}
}


